// routes
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Router from './routes';
// theme
import ThemeProvider from './theme';

import useLocales from './hooks/useLocales';
// hooks
// import useAuth from './hooks/useAuth';

// components
import { SettingsProvider } from './contexts/SettingsContext';
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import ConfirmDialog from './components/ConfirmDialog';
import { AnalyticsDataProvider } from './contexts/AnalyticsDataProvider';
import { StyledChart } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import { AuthProvider } from './contexts/JWTContext';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  const { currentLang } = useLocales();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLang.systemValue}>
      <SettingsProvider>
        <AnalyticsDataProvider>
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeSettings>
                <NotistackProvider>
                  <AuthProvider>
                    <ConfirmDialog />
                    <ProgressBarStyle />
                    <ScrollToTop />
                    <StyledChart />
                    <Router />
                  </AuthProvider>
                </NotistackProvider>
              </ThemeSettings>
            </ThemeProvider>
          </MotionLazyContainer>
        </AnalyticsDataProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
