import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import sampleReducer from './slices/sample';
import dataSetReducer from './slices/dataSet';
import groupSetReducer from './slices/groupSet';
import reportingReducer from './slices/reporting';
import dataElementReducer from './slices/dataElement';
import dataElementGroupReducer from './slices/dataElementGroup';
import dashboardReducer from './slices/dashboard';
import userReducer from './slices/user';
import roleReducer from './slices/role';
import dataFileReducer from './slices/dataFile';
import externalValueReducer from './slices/externalValue';
import entityReducer from './slices/entity';
import levelReducer from './slices/level';
import dataEntryReducer from './slices/dataEntry';
import stateReducer from './slices/state';
import analyticsReducer from './slices/analytics';
import contractsReducer from './slices/contracts';
import frontendReducer from './slices/frontend';
import cmsReducer from './slices/cms';
import groupReducer from './slices/group';
import bankReducer from './slices/bank';
import ruleReducer from './slices/rule';
import incentiveReducer from './slices/incentive';
import invoiceReducer from './slices/invoice';
import invoiceRequestReducer from './slices/invoiceRequest';
import publicationReducer from './slices/publication';
import integrationReducer from './slices/integration';
import exportReducer from './slices/export';
import customFrequencyReducer from './slices/customFrequency';
import workFlowReducer from './slices/workFlow';
import confirmDialogReducer from './slices/confirmDialog';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  sample: sampleReducer,
  contracts: contractsReducer,
  dataSet: dataSetReducer,
  reporting: reportingReducer,
  dataElement: dataElementReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  role: roleReducer,
  entity: entityReducer,
  dataFile: dataFileReducer,
  externalValue: externalValueReducer,
  dataEntry: dataEntryReducer,
  analytics: analyticsReducer,
  frontend: frontendReducer,
  state: stateReducer,
  groupSet: groupSetReducer,
  level: levelReducer,
  dataElementGroup: dataElementGroupReducer,
  cms: cmsReducer,
  group: groupReducer,
  bank: bankReducer,
  rule: ruleReducer,
  incentive: incentiveReducer,
  invoice: invoiceReducer,
  publication: publicationReducer,
  invoiceRequest: invoiceRequestReducer,
  integration: integrationReducer,
  export: exportReducer,
  customFrequency: customFrequencyReducer,
  workFlow: workFlowReducer,
  confirmDialog: confirmDialogReducer
});

export { rootPersistConfig, rootReducer };
