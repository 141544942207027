import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  title: null,
  message: null,
  okLabel: null,
  cancelLabel: null,
  onOK: null,
  onCancel: null,
  open: true
};

const slice = createSlice({
  name: 'confirmDialog',
  initialState,
  reducers: {
    setDialogOpen(state, action) {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.okLabel = action.payload.okLabel;
      state.cancelLabel = action.payload.cancelLabel;
      state.onOK = action.payload.onOK;
      state.onCancel = action.payload.onCancel;
      state.open = true;
    },
    setDialogClose(state) {
      // state.title = null;
      // state.message = null;
      // state.okLabel = null;
      // state.cancelLabel = null;
      state.onOK = null;
      state.onCancel = null;
      state.open = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setDialogOpen, setDialogClose } = slice.actions;
