import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { useDispatch, useSelector } from '../redux/store';
import { setDialogClose } from '../redux/slices/confirmDialog';
import { isNilOrBlank } from '../utils/core';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function ConfirmDialog() {
  const dispatch = useDispatch();
  const { title, message, open, okLabel, cancelLabel, onOK, onCancel } = useSelector((state) => state.confirmDialog);

  // const handleClickOk = () => {
  //   // setOpen(true);
  // };

  const handleClose = (e, r) => {
    if (r !== 'backdropClick' && r !== 'escapeKeyDown') {
      if (onCancel !== undefined) {
        onCancel();
      } else {
        dispatch(setDialogClose());
      }
    }
  };
  /* eslint-disable react/no-danger */
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={!isNilOrBlank(title) && !isNilOrBlank(message) && open}
      onClose={handleClose}
    >
      {!isNilOrBlank(title) && (
        <DialogTitle sx={{ pb: 1 }}>
          <ListItem disableGutters>
            <ListItemIcon sx={{ pr: 0 }}>
              <Iconify icon={'eva:alert-triangle-outline'} width={32} height={32} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'inherit' }} primary={title} />
          </ListItem>
        </DialogTitle>
      )}
      {!isNilOrBlank(message) && (
        <DialogContent sx={{ pb: 0 }}>
          <div dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }} />
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          {cancelLabel}
        </Button>
        <Button variant="outlined" color="primary" onClick={onOK}>
          {okLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
  /* eslint-enable react/no-danger */
}
